/**
 * Кастомизация mat-card.
 */

@import "../variables/global.scss";

.operator {

  .mat-card:not([class*=mat-elevation-z]) {
    box-shadow: $mat-card-shadow;
  }
}