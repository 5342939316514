/**
 * Кастомизация mat-select.
 */

@import "../variables/global.scss";

.operator {
  .mat-option,
  .mat-select-value {
    color: $text-color;
  }
  .mat-select-value {
    font-size: $large-text-size;
  }

  .mat-form-field-disabled {
    cursor: not-allowed;
    .mat-select-value {
      cursor: not-allowed;
    }
  }

  .mat-select-arrow-wrapper {
    .mat-select-arrow {
      color: $input-icon-color;
    }
    &:hover {
      .mat-select-arrow {
        color: $light-gray-color;
      }
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $border-color;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $light-gray-color;
  }
  .mat-form-field-should-float.mat-form-field-appearance-legacy
    .mat-form-field-label {
    font-size: 16px;
  }
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0.6em 0;
  }
}

/**
 * Кастомизация выпадашки для компонента SingleSelectComponent.
 * При наведении на выпадашку появляется кнопка очистки значения.
 */
.single-select {
  .mat-form-field-flex:hover {
    .mat-select-value {
      padding-right: 19px;
    }
  }
}
/**
 * Кастомизация выпадашки, при открытии меню варианты сортируются на несколько колонок, начало новой колонки
 * определяется блоком div, которым нужно оборачивать варианты, которые нужно вместить в одну колонку.
 */
.with-multicolumns.mat-select-panel {
  display: flex;
  max-width: inherit;
  max-height: inherit;
}

.mat-select-column {
  flex-grow: 1;
}
