/**
 * Кастомизация mat-dialog.
 */

@import "../variables/global.scss";

.operator {

  .mat-dialog-container {
    padding: $dlg-default-padding;
    color: $text-color;
  }

  .operator-simple-dlg .mat-dialog-container {
    padding-bottom: 0;
  }

  .operator-error-details-panel {
    .mat-dialog-container {
      padding: 0;

      .server-side-error-details .error-details__text a {
        color: $purple-color;
        font-weight: 500;
      }
    }
  }

  .dlg-panel {
    max-height: 87vh;

    .mat-dialog-container {
      position: relative;

      & > :first-child {
        position: relative;
      }
    }

    .mat-dialog-title {
      margin-bottom: 40px;
      font-size: 24px;
      font-weight: 300;
      line-height: 1.33;
    }

    .mat-dialog-actions {
      justify-content: flex-end;
      margin: 40px 0 0;
      padding: 0;
      min-height: 0;

      .mat-dialog__action-btn + .mat-dialog__action-btn {
        margin-left: 10px;
      }
    }

    .mat-dialog-cross-close-btn {
      position: absolute;
      top: -25px;
      right: -25px;
      display: flex;
      min-width: 36px;
      width: 36px;
      height: 36px;
      padding: 0;
      border-radius: 50%;
      justify-content: center;
      justify-items: center;
      align-items: center;
      background-color: transparent !important;
      box-shadow: none !important;

      .mat-button-wrapper {
        display: inline-flex;
        padding: 0;
      }

      &.mat-icon-button .mat-icon {
        height: 24px;
        width: 24px;
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
}
