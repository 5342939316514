/**
 * Глобальные стили компонента operator-document-processor.
 */
.operator-document-processor {

  .operator-page-viewer__txt-downloader {

    .mat-button-wrapper {
      flex: 1;
    }
  }
}

.company-from-space {
  display: block;
  margin: -10px -16px;
  padding: 10px 16px;
  background-color: #adef6ba8;

  &:hover {
    background-color: #a1d05aa8;
  }
}
