/**
 * Обобщённые правила для элемента, который внутри себя содержит лигатуру, которая будет отображена как символ из
 * шрифта.
 *
 * @param $font-name Название шрифта.
 */
@mixin icon-element($font-name) {
  font-family: $font-name;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/**
 * Правила для шрифта Roboto.
 *
 * @param $font-weight Насыщенность шрифта.
 * @param $src-local Имя шрифта на устройстве.
 * @param $src-local-second Дополнительное имя шрифта на устройстве.
 * @param $unicode-ranges Набор юникод диапазонов символов, которые будут загружены из шрифта.
 */
@mixin roboto-font-face($font-weight, $src-local, $src-local-second, $font-file-name, $unicode-ranges...) {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: $font-weight;
  src: local($src-local), local($src-local-second), url('/assets/fonts/' + $font-file-name + '.woff2') format('woff2');
  unicode-range: $unicode-ranges;
}