/**
 * Кастомизация ngx-mat-select-search.
 */

.operator {

  .single-select-panel {

    // Класс длинной панели
    &_long{
      max-height: 80vh !important;
    }

    // Корректно позиционируем блок, т.к. он немного наезжает на первое поле.
    ngx-mat-select-search {
      position: relative;
      top: -4px;
      display: block;

      .mat-select-search-no-entries-found {
        position: relative;
        top: 4px;
      }

      .mat-select-search-inner {
        border-bottom-color: rgba(0, 0, 0, 0.12);
      }

      // Убираем кнопку очистки поля поиска.
      .mat-select-search-clear {
        display: none;
      }

      // Расширяем поле поиска, т.к. удалили кнопку.
      .mat-select-search-input {
        padding: 20px 16px 12px;
      }
    }

    .mat-option {
      height: initial;
      min-height: 48px;
      white-space: normal;

      &.single-select__loading {

        .mat-option-text {
          display: flex;
          justify-content: center;
        }
      }

      &.single-select__invisible {
        height: 0;
        min-height: 0;
      }

      &:hover:not(.mat-option-disabled):not(.mat-selected),
      &:focus:not(.mat-option-disabled):not(.mat-selected),
      &.mat-active {
        background: rgba(0, 180, 130, 0.1);
      }

      .mat-option-text {
        padding: 10px 0;
        line-height: 20px;
        overflow: visible;
        text-overflow: initial;
      }

      .highlight {
        background: yellow;
      }
    }
  }
}
