@charset "UTF-8";
@import url(~@angular/material/prebuilt-themes/deeppurple-amber.css);
@import url(~handsontable/dist/handsontable.full.css);
/**
 * Обобщённые правила для элемента, который внутри себя содержит лигатуру, которая будет отображена как символ из
 * шрифта.
 *
 * @param $font-name Название шрифта.
 */
/**
 * Правила для шрифта Roboto.
 *
 * @param $font-weight Насыщенность шрифта.
 * @param $src-local Имя шрифта на устройстве.
 * @param $src-local-second Дополнительное имя шрифта на устройстве.
 * @param $unicode-ranges Набор юникод диапазонов символов, которые будут загружены из шрифта.
 */
/* fallback */
@font-face {
  font-family: 'Material Icons Custom Set';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/Material-Icons-Custom-Set.woff2") format("woff2"); }

.material-icons-custom-set {
  font-family: "Material Icons Custom Set";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/Material-Icons.woff2") format("woff2"); }

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url("/assets/fonts/Roboto-Thin-Cyrillic-Ext.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url("/assets/fonts/Roboto-Thin-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url("/assets/fonts/Roboto-Thin-Greek-Ext.woff2") format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url("/assets/fonts/Roboto-Thin-Greek.woff2") format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url("/assets/fonts/Roboto-Thin-Vietnamese.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url("/assets/fonts/Roboto-Thin-Latin-Ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url("/assets/fonts/Roboto-Thin-Latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("/assets/fonts/Roboto-Light-Cyrillic-Ext.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("/assets/fonts/Roboto-Light-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("/assets/fonts/Roboto-Light-Greek-Ext.woff2") format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("/assets/fonts/Roboto-Light-Greek.woff2") format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("/assets/fonts/Roboto-Light-Vietnamese.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("/assets/fonts/Roboto-Light-Latin-Ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("/assets/fonts/Roboto-Light-Latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("/assets/fonts/Roboto-Regular-Cyrillic-Ext.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("/assets/fonts/Roboto-Regular-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("/assets/fonts/Roboto-Regular-Greek-Ext.woff2") format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("/assets/fonts/Roboto-Regular-Greek.woff2") format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("/assets/fonts/Roboto-Regular-Vietnamese.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("/assets/fonts/Roboto-Regular-Latin-Ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("/assets/fonts/Roboto-Regular-Latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/assets/fonts/Roboto-Medium-Cyrillic-Ext.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/assets/fonts/Roboto-Medium-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/assets/fonts/Roboto-Medium-Greek-Ext.woff2") format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/assets/fonts/Roboto-Medium-Greek.woff2") format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/assets/fonts/Roboto-Medium-Vietnamese.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/assets/fonts/Roboto-Medium-Latin-Ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/assets/fonts/Roboto-Medium-Latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  height: 100%; }

body {
  display: flex;
  min-height: 100%;
  background-color: #f8f8f8;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: #2d2d2d;
  font-size: 14px; }

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px; }

.fluid-container {
  margin: 0;
  padding-left: 32px;
  padding-right: 32px; }

.loading-spinner-container {
  width: 100%;
  top: 50%;
  position: relative;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }
  .loading-spinner-container mat-spinner {
    margin: auto; }

.text-center {
  text-align: center; }

.help-cursor {
  cursor: help; }

.fit-content-height {
  min-height: fit-content; }

.operator .with-header {
  padding-top: 57px; }

.disabled-like-block .mat-input-element:disabled {
  color: inherit; }

.disabled-like-block .mat-form-field-underline {
  visibility: hidden; }

.disabled-like-block mat-select .mat-select-value {
  display: inline;
  color: inherit; }

.disabled-like-block mat-select .mat-select-arrow-wrapper {
  display: none; }

.fixed-badge .mat-badge-content {
  width: auto !important;
  padding: 0 16px !important;
  border-radius: 15px; }

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px; }

/**
 * Кастомизация mat-select.
 */
.operator .mat-option,
.operator .mat-select-value {
  color: #2d2d2d; }

.operator .mat-select-value {
  font-size: 16px; }

.operator .mat-form-field-disabled {
  cursor: not-allowed; }
  .operator .mat-form-field-disabled .mat-select-value {
    cursor: not-allowed; }

.operator .mat-select-arrow-wrapper .mat-select-arrow {
  color: #bebebe; }

.operator .mat-select-arrow-wrapper:hover .mat-select-arrow {
  color: #8c8c8c; }

.operator .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #e0e0e0; }

.operator .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #8c8c8c; }

.operator .mat-form-field-should-float.mat-form-field-appearance-legacy
.mat-form-field-label {
  font-size: 16px; }

.operator .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.6em 0; }

/**
 * Кастомизация выпадашки для компонента SingleSelectComponent.
 * При наведении на выпадашку появляется кнопка очистки значения.
 */
.single-select .mat-form-field-flex:hover .mat-select-value {
  padding-right: 19px; }

/**
 * Кастомизация выпадашки, при открытии меню варианты сортируются на несколько колонок, начало новой колонки
 * определяется блоком div, которым нужно оборачивать варианты, которые нужно вместить в одну колонку.
 */
.with-multicolumns.mat-select-panel {
  display: flex;
  max-width: inherit;
  max-height: inherit; }

.mat-select-column {
  flex-grow: 1; }

/**
 * Кастомизация mat-badge.
 */
.mat-badge.disable-animation .mat-badge-content {
  transition: none;
  -webkit-transform: none;
  transform: none; }

/**
 * Кастомизация mat-card.
 */
.operator .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08); }

/**
 * Кастомизация mat-icon.
 */
.operator {
  /**
     * Кастомизация иконки-кнопки для очистки компонента SingleSelectComponent.
     * При наведении на выпадашку появляется кнопка очистки значения.
     */ }
  .operator .mat-icon {
    height: 18px;
    width: 18px;
    font-size: 18px;
    line-height: 18px;
    color: #868686;
    text-align: center; }
    .operator .mat-icon_large {
      height: 28px;
      width: 28px;
      font-size: 28px;
      line-height: 28px; }
      .operator .mat-icon_large.mat-icon-button .mat-icon {
        height: 24px;
        width: 24px;
        font-size: 24px;
        line-height: 24px; }
    .operator .mat-icon_red {
      color: #f44336; }
    .operator .mat-icon_green {
      color: #673ab7; }
    .operator .mat-icon_font {
      color: #2d2d2d; }
  .operator .mat-icon-button .mat-icon {
    position: relative;
    top: -1px;
    line-height: 18px; }
    .operator .mat-icon-button .mat-icon[fontset] {
      top: -2px; }
  .operator .mat-raised-button .mat-icon,
  .operator .mat-button .mat-icon {
    margin-right: 6px;
    color: inherit; }
    .operator .mat-raised-button .mat-icon.mat-icon_right,
    .operator .mat-button .mat-icon.mat-icon_right {
      margin-right: 0;
      margin-left: 6px; }
  .operator .operator-panel .mat-menu-item:hover .mat-icon.mat-icon_red {
    color: #e63c32; }
  .operator .operator-panel .mat-menu-item:hover .mat-icon.mat-icon_green {
    color: #673ab7; }
  .operator .operator-panel .mat-menu-item:active .mat-icon.mat-icon_red {
    color: #e63c32; }
  .operator .operator-panel .mat-menu-item:active .mat-icon.mat-icon_green {
    color: #673ab7; }
  .operator .operator-panel .mat-menu-item .mat-icon {
    color: #868686; }
    .operator .operator-panel .mat-menu-item .mat-icon.mat-icon_red {
      color: #f44336; }
    .operator .operator-panel .mat-menu-item .mat-icon.mat-icon_green {
      color: #673ab7; }
  .operator .single-select .mat-form-field-flex:hover .single-select__clear-btn {
    display: block; }

/**
 * Кастомизация mat-menu при отображении его в overlay'е с дополнительными CSS-классами.
 */
.operator-panel.operator-mat-menu-panel .mat-menu-item {
  height: 40px;
  line-height: 40px;
  font-size: 14px; }
  .operator-panel.operator-mat-menu-panel .mat-menu-item[disabled], .operator-panel.operator-mat-menu-panel .mat-menu-item[disabled]::after {
    color: #8c8c8c; }
  .operator-panel.operator-mat-menu-panel .mat-menu-item .mat-icon {
    position: relative;
    top: -1px;
    margin-right: 8px; }
  .operator-panel.operator-mat-menu-panel .mat-menu-item.mat-menu-item_selected > span {
    font-weight: 500; }
  .operator-panel.operator-mat-menu-panel .mat-menu-item.mat-menu-item_green {
    color: #673ab7; }
    .operator-panel.operator-mat-menu-panel .mat-menu-item.mat-menu-item_green .mat-icon {
      color: #673ab7; }
    .operator-panel.operator-mat-menu-panel .mat-menu-item.mat-menu-item_green:hover {
      color: #673ab7; }
  .operator-panel.operator-mat-menu-panel .mat-menu-item.mat-menu-item_red {
    color: #f44336; }
    .operator-panel.operator-mat-menu-panel .mat-menu-item.mat-menu-item_red .mat-icon {
      color: #f44336; }
    .operator-panel.operator-mat-menu-panel .mat-menu-item.mat-menu-item_red:hover {
      color: #e63c32; }
  .operator-panel.operator-mat-menu-panel .mat-menu-item.mat-menu-item_right-icon {
    display: flex;
    align-items: center; }
    .operator-panel.operator-mat-menu-panel .mat-menu-item.mat-menu-item_right-icon > span {
      margin-right: 30px;
      flex-grow: 1; }
    .operator-panel.operator-mat-menu-panel .mat-menu-item.mat-menu-item_right-icon .menu-item__right-icon {
      margin-right: 0; }

/**
 * Кастомизация mat-tooltip.
 */
.mat-tooltip {
  font-size: 12px; }

/**
 * Кастомизация mat-datepicker.
 */
.operator .operator-date-picker.mat-focused .mat-datepicker-toggle {
  color: #673ab7; }

.operator .operator-date-picker .mat-datepicker-toggle {
  color: #bebebe; }

.operator .operator-date-picker .mat-datepicker-toggle-active {
  color: #673ab7; }

.operator .operator-date-picker .mat-icon-button.cdk-focused {
  color: #673ab7; }

.operator .operator-date-picker .mat-datepicker-toggle-default-icon {
  position: relative;
  top: -1px; }

.operator .operator-date-picker .mat-form-field-suffix {
  position: relative;
  left: 1px; }

.operator .operator-date-picker .mat-form-field-infix {
  height: 18px;
  padding: 0.7em 0 0.5em; }
  .operator .operator-date-picker .mat-form-field-infix .mat-input-element {
    font-size: 16px; }

/**
 * Кастомизация mat-dialog.
 */
.operator .mat-dialog-container {
  padding: 40px;
  color: #2d2d2d; }

.operator .operator-simple-dlg .mat-dialog-container {
  padding-bottom: 0; }

.operator .operator-error-details-panel .mat-dialog-container {
  padding: 0; }
  .operator .operator-error-details-panel .mat-dialog-container .server-side-error-details .error-details__text a {
    color: #673ab7;
    font-weight: 500; }

.operator .dlg-panel {
  max-height: 87vh; }
  .operator .dlg-panel .mat-dialog-container {
    position: relative; }
    .operator .dlg-panel .mat-dialog-container > :first-child {
      position: relative; }
  .operator .dlg-panel .mat-dialog-title {
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.33; }
  .operator .dlg-panel .mat-dialog-actions {
    justify-content: flex-end;
    margin: 40px 0 0;
    padding: 0;
    min-height: 0; }
    .operator .dlg-panel .mat-dialog-actions .mat-dialog__action-btn + .mat-dialog__action-btn {
      margin-left: 10px; }
  .operator .dlg-panel .mat-dialog-cross-close-btn {
    position: absolute;
    top: -25px;
    right: -25px;
    display: flex;
    min-width: 36px;
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 50%;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-color: transparent !important;
    box-shadow: none !important; }
    .operator .dlg-panel .mat-dialog-cross-close-btn .mat-button-wrapper {
      display: inline-flex;
      padding: 0; }
    .operator .dlg-panel .mat-dialog-cross-close-btn.mat-icon-button .mat-icon {
      height: 24px;
      width: 24px;
      font-size: 24px;
      line-height: 24px; }

.operator .mat-h1 {
  margin-bottom: 32px; }
  .operator .mat-h1_slim {
    font-weight: 300; }
  .operator .mat-h1 .mat-icon {
    height: 24px;
    width: 24px;
    font-size: 24px;
    line-height: 24px;
    position: relative;
    top: 3px; }

.operator .mat-progress-bar .mat-progress-bar-buffer {
  background-color: #f0f0f0; }

.operator .mat-progress-bar_thin {
  height: 2px; }

.operator .mat-progress-bar .mat-progress-bar-fill {
  transition: none; }

/**
 * Кастомизация mat-tabs.
 */
.operator .mat-tab-nav-bar,
.operator .mat-tab-header {
  border-bottom: none; }

.operator .mat-tab-link {
  color: #8c8c8c;
  font-weight: 400;
  opacity: 1; }

.operator .mat-tab-label-active {
  color: #2d2d2d;
  font-weight: 500; }

/**
 * Кастомизация ngx-mat-select-search.
 */
.operator .single-select-panel_long {
  max-height: 80vh !important; }

.operator .single-select-panel ngx-mat-select-search {
  position: relative;
  top: -4px;
  display: block; }
  .operator .single-select-panel ngx-mat-select-search .mat-select-search-no-entries-found {
    position: relative;
    top: 4px; }
  .operator .single-select-panel ngx-mat-select-search .mat-select-search-inner {
    border-bottom-color: rgba(0, 0, 0, 0.12); }
  .operator .single-select-panel ngx-mat-select-search .mat-select-search-clear {
    display: none; }
  .operator .single-select-panel ngx-mat-select-search .mat-select-search-input {
    padding: 20px 16px 12px; }

.operator .single-select-panel .mat-option {
  height: initial;
  min-height: 48px;
  white-space: normal; }
  .operator .single-select-panel .mat-option.single-select__loading .mat-option-text {
    display: flex;
    justify-content: center; }
  .operator .single-select-panel .mat-option.single-select__invisible {
    height: 0;
    min-height: 0; }
  .operator .single-select-panel .mat-option:hover:not(.mat-option-disabled):not(.mat-selected), .operator .single-select-panel .mat-option:focus:not(.mat-option-disabled):not(.mat-selected), .operator .single-select-panel .mat-option.mat-active {
    background: rgba(0, 180, 130, 0.1); }
  .operator .single-select-panel .mat-option .mat-option-text {
    padding: 10px 0;
    line-height: 20px;
    overflow: visible;
    text-overflow: initial; }
  .operator .single-select-panel .mat-option .highlight {
    background: yellow; }

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.cdk-overlay-backdrop_dark {
  background: #000;
  opacity: 0.4; }

.error-details__text {
  margin-bottom: 32px;
  font-size: 12px;
  line-height: 1.67; }
  .error-details__text_highlighted {
    color: #673ab7;
    font-weight: 500; }

.htCore .htSerialNumberColumn {
  background: #f0f0f0;
  color: #2d2d2d; }

.htCore .htFirstPageRow {
  background: #e5f7f2; }

.htCore .htMarkedRow {
  background: #e5f7f2; }

.htCore .htEndPageRow {
  border-bottom: 2px solid; }

.htCore .htCustomColor {
  background-color: #e4b35d; }

.htCore .htChanged.htInvalid {
  background: #ffa500 !important; }

.handsontable .handsontableInputHolder {
  max-width: 50%;
  z-index: 99999; }
  .handsontable .handsontableInputHolder .handsontableInput {
    max-width: 50% !important; }

/**
 * Глобальные стили компонента operator-document-processor.
 */
.operator-document-processor .operator-page-viewer__txt-downloader .mat-button-wrapper {
  flex: 1; }

.company-from-space {
  display: block;
  margin: -10px -16px;
  padding: 10px 16px;
  background-color: #adef6ba8; }
  .company-from-space:hover {
    background-color: #a1d05aa8; }

/**
 * Глобальные стили компонента processing-errors-dialog
 */
.processing-errors-pane {
  width: 75%;
  min-width: 750px; }
  .processing-errors-pane .mat-dialog-container {
    padding-bottom: 20px; }

.registration-form__privacy-note a {
  color: #2d2d2d; }
