@import "../variables/global";

.htCore {

  .htSerialNumberColumn {
    background: #f0f0f0;
    color: $text-color;
  }

  .htFirstPageRow {
    background: $light-green-color;
  }

  .htMarkedRow {
    background: $light-green-color;
  }

  .htEndPageRow {
    border-bottom: 2px solid;
  }

  .htCustomColor {
    background-color: #e4b35d;
  }

  .htChanged.htInvalid {
    background: $orange-color !important;
  }
}

.handsontable {

  // Поднятие редактируемой ячейки наверх, чтобы её не скрывал просмотрщик страниц документа, и ограничение ширины
  // редактируемой ячейки, чтобы она не расползалась на всю ширину экрана.
  .handsontableInputHolder {
    max-width: 50%;
    z-index: 99999;

    .handsontableInput {
      max-width: 50% !important;
    }
  }
}
