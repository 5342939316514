@import "../variables/global.scss";

.error-details {
  &__text {
    margin-bottom: 32px;
    font-size: $small-text-size;
    line-height: 1.67;

    &_highlighted {
      color: $purple-hover-color;
      font-weight: 500;
    }
  }
}
