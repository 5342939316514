@import "mixins";

/* fallback */
@font-face {
  font-family: 'Material Icons Custom Set';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Material-Icons-Custom-Set.woff2') format('woff2');
}

.material-icons-custom-set {
  @include icon-element('Material Icons Custom Set');
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Material-Icons.woff2') format('woff2');
}

.material-icons {
  @include icon-element('Material Icons');
}

/* cyrillic-ext */
@font-face {
  @include roboto-font-face(
    100,
    'Roboto Thin',
    'Roboto-Thin',
    'Roboto-Thin-Cyrillic-Ext',
    U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
  );
}
/* cyrillic */
@font-face {
  @include roboto-font-face(
    100,
    'Roboto Thin',
    'Roboto-Thin',
    'Roboto-Thin-Cyrillic',
    U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
  );
}
/* greek-ext */
@font-face {
  @include roboto-font-face(
    100,
    'Roboto Thin',
    'Roboto-Thin',
    'Roboto-Thin-Greek-Ext',
    U+1F00-1FFF
  );
}
/* greek */
@font-face {
  @include roboto-font-face(
    100,
    'Roboto Thin',
    'Roboto-Thin',
    'Roboto-Thin-Greek',
    U+0370-03FF
  );
}
/* vietnamese */
@font-face {
  @include roboto-font-face(
    100,
    'Roboto Thin',
    'Roboto-Thin',
    'Roboto-Thin-Vietnamese',
    U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
  );
}
/* latin-ext */
@font-face {
  @include roboto-font-face(
    100,
    'Roboto Thin',
    'Roboto-Thin',
    'Roboto-Thin-Latin-Ext',
    U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
  );
}
/* latin */
@font-face {
  @include roboto-font-face(
    100,
    'Roboto Thin',
    'Roboto-Thin',
    'Roboto-Thin-Latin',
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD
  );
}
/* cyrillic-ext */
@font-face {
  @include roboto-font-face(
    300,
    'Roboto Light',
    'Roboto-Light',
    'Roboto-Light-Cyrillic-Ext',
    U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
  );
}
/* cyrillic */
@font-face {
  @include roboto-font-face(
    300,
    'Roboto Light',
    'Roboto-Light',
    'Roboto-Light-Cyrillic',
    U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
  );
}
/* greek-ext */
@font-face {
  @include roboto-font-face(
    300,
    'Roboto Light',
    'Roboto-Light',
    'Roboto-Light-Greek-Ext',
    U+1F00-1FFF
  );
}
/* greek */
@font-face {
  @include roboto-font-face(
    300,
    'Roboto Light',
    'Roboto-Light',
    'Roboto-Light-Greek',
    U+0370-03FF
  );
}
/* vietnamese */
@font-face {
  @include roboto-font-face(
    300,
    'Roboto Light',
    'Roboto-Light',
    'Roboto-Light-Vietnamese',
    U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
  );
}
/* latin-ext */
@font-face {
  @include roboto-font-face(
    300,
    'Roboto Light',
    'Roboto-Light',
    'Roboto-Light-Latin-Ext',
    U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
  );
}
/* latin */
@font-face {
  @include roboto-font-face(
    300,
    'Roboto Light',
    'Roboto-Light',
    'Roboto-Light-Latin',
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
  );
}
/* cyrillic-ext */
@font-face {
  @include roboto-font-face(
    400,
    'Roboto',
    'Roboto-Regular',
    'Roboto-Regular-Cyrillic-Ext',
    U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
  );
}
/* cyrillic */
@font-face {
  @include roboto-font-face(
    400,
    'Roboto',
    'Roboto-Regular',
    'Roboto-Regular-Cyrillic',
    U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
  );
}
/* greek-ext */
@font-face {
  @include roboto-font-face(
    400,
    'Roboto',
    'Roboto-Regular',
    'Roboto-Regular-Greek-Ext',
    U+1F00-1FFF
  );
}
/* greek */
@font-face {
  @include roboto-font-face(
    400,
    'Roboto',
    'Roboto-Regular',
    'Roboto-Regular-Greek',
    U+0370-03FF
  );
}
/* vietnamese */
@font-face {
  @include roboto-font-face(
    400,
    'Roboto',
    'Roboto-Regular',
    'Roboto-Regular-Vietnamese',
    U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
  );
}
/* latin-ext */
@font-face {
  @include roboto-font-face(
    400,
    'Roboto',
    'Roboto-Regular',
    'Roboto-Regular-Latin-Ext',
    U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
  );
}
/* latin */
@font-face {
  @include roboto-font-face(
    400,
    'Roboto',
    'Roboto-Regular',
    'Roboto-Regular-Latin',
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
  );
}
/* cyrillic-ext */
@font-face {
  @include roboto-font-face(
    500,
    'Roboto Medium',
    'Roboto-Medium',
    'Roboto-Medium-Cyrillic-Ext',
    U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
  );
}
/* cyrillic */
@font-face {
  @include roboto-font-face(
    500,
    'Roboto Medium',
    'Roboto-Medium',
    'Roboto-Medium-Cyrillic',
    U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
  );
}
/* greek-ext */
@font-face {
  @include roboto-font-face(
    500,
    'Roboto Medium',
    'Roboto-Medium',
    'Roboto-Medium-Greek-Ext',
    U+1F00-1FFF)
;
}
/* greek */
@font-face {
  @include roboto-font-face(
    500,
    'Roboto Medium',
    'Roboto-Medium',
    'Roboto-Medium-Greek',
    U+0370-03FF
  );
}
/* vietnamese */
@font-face {
  @include roboto-font-face(
    500,
    'Roboto Medium',
    'Roboto-Medium',
    'Roboto-Medium-Vietnamese',
     U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB
  );
}
/* latin-ext */
@font-face {
  @include roboto-font-face(
    500,
    'Roboto Medium',
    'Roboto-Medium',
    'Roboto-Medium-Latin-Ext',
    U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
  );
}
/* latin */
@font-face {
  @include roboto-font-face(
    500,
    'Roboto Medium',
    'Roboto-Medium',
    'Roboto-Medium-Latin',
    U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD
  );
}