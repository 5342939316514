/**
 * Кастомизация mat-menu при отображении его в overlay'е с дополнительными CSS-классами.
 */

@import "../variables/global.scss";

.operator-panel.operator-mat-menu-panel .mat-menu-item {
  height: $mat-menu-item-height;
  line-height: $mat-menu-item-height;
  font-size: $medium-text-size;

  &[disabled],
  &[disabled]::after {
    color: $light-gray-color;
  }

  & .mat-icon {
    position: relative;
    top: -1px;
    margin-right: 8px;
  }

  &.mat-menu-item_selected {
    > span {
      font-weight: 500;
    }
  }

  &.mat-menu-item_green {
    color: $purple-color;

    & .mat-icon {
        color: $purple-color;
    }

    &:hover {
      color: $purple-hover-color;
    }
  }

  &.mat-menu-item_red {
    color: $red-color;

    & .mat-icon {
        color: $red-color;
    }

    &:hover {
      color: $red-hover-color;      
    }
  }

  // Выпадашка, когда иконки справа.
  &.mat-menu-item_right-icon {
    display: flex;
    align-items: center;

    > span {
      margin-right: 30px;
      flex-grow: 1;
    }

    & .menu-item__right-icon {
      margin-right: 0;
    }
  }
}
