/**
 * Глобальные стили компонента processing-errors-dialog
 */

.processing-errors-pane {
  width: 75%;
  min-width: 750px;

  .mat-dialog-container {
    padding-bottom: 20px;
  }
}
